<template>
    <div class="vx-row no-gutter justify-center items-center" >
        <vx-card class="vx-row justify-center" id="changeBackGround" >
            <div slot="no-body" class="tabs-container  pb-10 px-10 pt-10" id="container-change-password">
                <!-- Content Row -->
                <div class="vx-row" id="customPadding" @change="listensChangeForm">
                    <div class="vx-col md:w-full w-full mt-2">
                        <vs-button v-if="activeIconEye" type="flat" class="float-right mt-6" icon="icon-eye"
                                   icon-pack="feather" icon-no-border @click="HiddenShowPassWord"
                                   style="margin-left: -68px"></vs-button>
                        <vs-button v-if="activeIconEyeOff" type="flat" class="float-right mt-6" icon="icon-eye-off"
                                   icon-pack="feather" icon-no-border @click="HiddenShowPassWord"
                                   style="margin-left: -68px"></vs-button>
                        <vs-input class="w-full" :label="$t('resetPassword.oldPass')" v-model="branchInfo.oldPassword"
                                  autocomplete="off"
                                  :placeholder="$t('resetPassword.reOldPass')" :clearable="true" @input="hidePassErr"
                                  v-validate="'required|min:6'" :type="typeOption" name="oldPassWord"/>
                        <span class="text-danger text-sm pLeftErr">{{ errors.first('oldPassword') }}</span>
                        <span id="oldPassWord"></span>
                    </div>
                    <div class="vx-col md:w-full w-full mt-2">
                        <vs-button v-if="activeIconEyeNewPassWord" type="flat" class="float-right mt-6" icon="icon-eye"
                                   icon-pack="feather" icon-no-border button-no-border @click="HiddenShowNewPassWord"
                                   style="margin-left: -68px"></vs-button>
                        <vs-button v-if="activeIconEyeOffNewPassWord" type="flat" class="float-right mt-6" icon="icon-eye-off"
                                   icon-pack="feather" icon-no-border @click="HiddenShowNewPassWord"
                                   style="margin-left: -68px"></vs-button>
                        <vs-input class="w-full" :label="$t('resetPassword.newPass')" v-model="branchInfo.newPassword"
                                  :placeholder="$t('resetPassword.reNewPass')" autocomplete="off"
                                  @input="passwordChanged" v-validate="'required|min:6'" :type="typeOptionNewPassWord"
                                  name="newPassWord"/>
                        <span id="strength"></span>
                    </div>
                    <div class="vx-col md:w-full w-full mt-2">
                        <vs-button v-if="activeIconEyeReNewPassWord" type="flat" class="float-right mt-6" icon="icon-eye"
                                   icon-pack="feather" icon-no-border button-no-border @click="HiddenShowReNewPassWord"
                                   style="margin-left: -68px"></vs-button>
                        <vs-button v-if="activeIconEyeOffReNewPassWord" type="flat" class="float-right mt-6" icon="icon-eye-off"
                                   icon-pack="feather" icon-no-border @click="HiddenShowReNewPassWord"
                                   style="margin-left: -68px"></vs-button>
                        <vs-input class="w-full" :label="$t('resetPassword.retype')" v-model="branchInfo.reNewPassword"
                                 :placeholder="$t('resetPassword.rePassword')" autocomplete="off"
                                  @input="passwordReNew" :type="typeOptionReNewPassWord" v-validate="'required|min:6'"
                                  name="reNewPassWord"/>
                        <span id="strengthReNew"></span>
                    </div>
                </div>

                <!-- Save & Reset Button -->
                <div class="vx-row">
                    <div class="vx-col w-full">
                        <div class="mt-8 flex flex-wrap items-center justify-end">
                            <vs-button class="ml-auto mt-2" id="button-change-password" @click="saveChanges" :disabled="!buttonValidateForm">
                                {{$t('resetPassword.resetPass')}}
                            </vs-button>
                            <vs-button class="ml-4 mt-2" type="border" color="warning" @click="backHome"> {{$t('button.back')}}
                            </vs-button>
                        </div>
                    </div>
                </div>
            </div>
        </vx-card>
    </div>
</template>

<script>

export default {

    data() {
        return {
            typeOption: 'password',
            activeIconEye: true,
            activeIconEyeOff: false,
            typeOptionNewPassWord: 'password',
            activeIconEyeNewPassWord: true,
            activeIconEyeOffNewPassWord: false,
            typeOptionReNewPassWord: 'password',
            activeIconEyeReNewPassWord: true,
            activeIconEyeOffReNewPassWord: false,
            buttonValidateForm: false,
            branchInfo: {},
        }
    },
    computed: {
        validateForm() {
            return !this.errors.any()
        }
    },
    methods: {
        async saveChanges(evt) {
            this.regexPass().then(async (res) => {
                if (res == true) {
                    evt.preventDefault();
                    try {
                        const result = await this.$validator.validateAll();
                        if (!result) return;
                        let url = '/user/change-password',
                            data = this.branchInfo;
                        this.$vs.loading();
                        this.$oauth.post(url, data).then(() => {
                            this.$vs.notify({
                                color: 'success',
                                text: this.$t('resetPassword.textSuccess'),
                                iconPack: 'feather',
                                icon: 'icon-alert-circle',
                            });
                            this.$vs.loading.close();
                            localStorage.removeItem("access_token");
                            // location.reload();
                            this.$router.push(`/pages/login`).catch(() => {})
                        }).catch((err) => {
                            this.$vs.loading.close();
                            return document.getElementById('oldPassWord').innerHTML=`<span style="color: red"><b>${this.$t('resetPassword.pwdOldValid')}</b></span>`;
                            // return this.$vs.notify({
                            //     text: err.response.data.message || err.response.data.error_description || err.response.data.error,
                            //     iconPack: 'feather',
                            //     icon: 'icon-alert-circle',
                            //     color: 'danger'
                            // });
                        });
                    } catch (ex) {
                        console.log(ex);
                    }
                } else {
                    this.buttonValidateForm = false;
                }
            });
        },
        hidePassErr(val){
            return document.getElementById('oldPassWord').innerHTML = '';
        },
        passwordChanged() {
            let strength = document.getElementById('strength');
            let strongRegex = new RegExp("^(?=.{10,})(?=.*[A-Z])(?=.*[a-z])(?=.*[0-9])(?=.*\\W).*$", "g");
            let mediumRegex = new RegExp("^(?=.{8,})(((?=.*[A-Z])(?=.*[a-z]))|((?=.*[A-Z])(?=.*[0-9]))|((?=.*[a-z])(?=.*[0-9]))).*$", "g");
            let enoughRegex = new RegExp("(?=.{6,}).*", "g");
            const pwd = this.branchInfo.newPassword;
            if (pwd.length == 0) {
                strength.innerHTML = `<span class="text-sm pLeftErr" style="color:red">${this.$t('resetPassword.reNewPass')}</span>`;
                document.getElementById('strengthReNew').innerHTML='';
            } else if (enoughRegex.test(pwd) ==  false) {
                this.buttonValidateForm = false;
                strength.innerHTML = `<span class="text-sm pLeftErr">${this.$t('resetPassword.pwLength6')}</span>`;
                document.getElementById('strengthReNew').innerHTML='';
            } else if (strongRegex.test(pwd)) {
                strength.innerHTML = `<span class="text-sm pLeftErr">${this.$t('resetPassword.pwLength')}: <b style="color:green">${this.$t('resetPassword.strong')}</b></span>`;
            } else if (mediumRegex.test(pwd)) {
                strength.innerHTML = `<span class="text-sm pLeftErr">${this.$t('resetPassword.pwLength')}: <b style="color:orange">${this.$t('resetPassword.medium')}</b></span>`;
            } else {
                strength.innerHTML = `<span class="text-sm pLeftErr">${this.$t('resetPassword.pwLength')}: <b style="color:red">${this.$t('resetPassword.tooShort')}!</b></span>`;
            }
            if(this.branchInfo.newPassword){
                this.passwordReNew();
            }else {
                document.getElementById('strengthReNew').innerHTML='';
            }
        },
            passwordReNew() {
            let strength = document.getElementById('strengthReNew');
            const pwd = this.branchInfo.reNewPassword;
            const pwdCheck = this.branchInfo.newPassword;
            if(pwd.length < pwdCheck.length){
                strength.innerHTML = `<span class="text-sm pLeftErr" style="color:#fea500"><b>${this.$t('resetPassword.confirmPwdTooShort')}</b></span>`;
                this.buttonValidateForm = false;
            }else {
                if(pwd === pwdCheck && pwdCheck.length){
                    strength.innerHTML = `<span class="text-sm pLeftErr" style="color:#008000"><b>${this.$t('resetPassword.matched')}</b></span>`;
                    this.buttonValidateForm = true;
                }else {
                    strength.innerHTML = `<span class="text-sm pLeftErr" style="color:#fea500"><b>${this.$t('resetPassword.notMatched')}</b></span>`;
                    this.buttonValidateForm = false;
                }
            }
            if(!pwd){
                document.getElementById('strengthReNew').innerHTML='';
            }
        },
        regexPass(){
            return new Promise((resolve, reject)=>{
                let strength = document.getElementById('strength');
                let strengthReNew = document.getElementById('strengthReNew');
                let vietnameseRegex = new RegExp("^[a-zA-ZÀÁÂÃÈÉÊÌÍÒÓÔÕÙÚĂĐĨŨƠàáâãèéêìíòóôõùúăđĩũơƯĂẠẢẤẦẨẪẬẮẰẲẴẶẸẺẼỀỀỂẾưăạảấầẩẫậắằẳẵặẹẻẽềềểếỄỆỈỊỌỎỐỒỔỖỘỚỜỞỠỢỤỦỨỪễệỉịọỏốồổỗộớờởỡợụủứừỬỮỰỲỴÝỶỸửữựỳỵỷỹ\s\W|_]+$", "g")
                let spaceRegex = new RegExp("\\\s.*$", "g")
                const passNew = this.branchInfo.newPassword;
                if(vietnameseRegex.test(passNew) || spaceRegex.test(passNew)){
                    strength.innerHTML = `<span class="text-sm pLeftErr" style="color:red">${this.$t('resetPassword.validatorPwd')}</span>`;
                    strengthReNew.innerHTML = `<span class="text-sm pLeftErr" style="color:red">${this.$t('resetPassword.validatorPwd')}</span>`;
                    resolve(false)
                }else {
                    resolve(true)
                }
            })
        },

        listensChangeForm() {
            if (this.branchInfo.newPassword === this.branchInfo.reNewPassword && this.branchInfo.oldPassword !== '') {
                this.buttonValidateForm = true
            }
            if (this.branchInfo.reNewPassword == '' || this.branchInfo.oldPassword == '' || this.branchInfo.newPassword == '' ||
                this.branchInfo.reNewPassword == undefined || this.branchInfo.oldPassword == undefined || this.branchInfo.newPassword == undefined) {
                this.buttonValidateForm = false
            }

        },
        listensChangePassWord() {
            if (this.branchInfo.reNewPassword !== undefined && this.branchInfo.newPassword == undefined) {
                this.buttonValidateForm = false;
                return this.$vs.notify({
                    color: 'danger',
                    text: this.$t('resetPassword.textNotPass'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                });
            }
            if (this.branchInfo.newPassword === this.branchInfo.reNewPassword && this.branchInfo.newPassword !== '' && this.branchInfo.reNewPassword !== '')  {
                this.$vs.notify({
                    color: 'success',
                    text: this.$t('resetPassword.matched'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                });
            }
            if (this.branchInfo.newPassword !== this.branchInfo.reNewPassword && this.branchInfo.newPassword.length > 0) {
                this.buttonValidateForm = false;
                this.$vs.notify({
                    color: 'danger',
                    text:  this.$t('resetPassword.notMatched'),
                    iconPack: 'feather',
                    icon: 'icon-alert-circle',
                });
            }
        },
        HiddenShowPassWord() {
            this.activeIconEyeOff = !this.activeIconEyeOff
            if (this.typeOption == 'password') {
                this.typeOption = 'text'
            } else {
                this.typeOption = 'password'
            }
        },
        HiddenShowNewPassWord() {
            this.activeIconEyeOffNewPassWord = !this.activeIconEyeOffNewPassWord
            if (this.typeOptionNewPassWord == 'password') {
                this.typeOptionNewPassWord = 'text'
            } else {
                this.typeOptionNewPassWord = 'password'
            }
        },
        HiddenShowReNewPassWord() {
            this.activeIconEyeOffReNewPassWord = !this.activeIconEyeOffReNewPassWord
            if (this.typeOptionReNewPassWord == 'password') {
                this.typeOptionReNewPassWord = 'text'
            } else {
                this.typeOptionReNewPassWord = 'password'
            }
        },
        backHome() {
            this.$router.push(`/`).catch(() => {
            })
        }
    },
}
</script>

<style lang="scss" scoped>
#container-change-password {
    max-width: 450px !important;
    margin-left: 300px!important;
}

@media only screen and (max-width: 840px) {
    #container-change-password {
        margin-left: 1px!important;
    }
    #changeBackGround {
        background-image: url("../../../assets/images/pages/vuexy-login-bg.jpg")!important;
        background-position: center;
        background-repeat: no-repeat;
        background-size: cover;
    }
}
#changeBackGround {
    background-image: url("../../../assets/images/pages/reset-password.png");
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}
</style>
<style lang="scss">
#customPadding {
    .vs-input--input.normal{
    padding: 0.7rem !important;
    padding-right: 36px!important;
    font-size: 1.07rem !important;
    }
}
#button-change-password {
    padding-right: 67px!important;
    padding-left: 67px!important;
}
</style>
